import { useEffect, useState } from "react";
import "./App.css";

function App() {
  const calculateTimeLeft = () => {
    const difference = +new Date('2023-04-04T12:00:00+00:00') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor(difference / (1000 * 60 * 60)),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  return (
    <div className="App">
		<div className="Timer">
			{timeLeft.hours || timeLeft.minutes || timeLeft.seconds ? (
				<h1 className="TimerText">
				<span>{timeLeft.hours}</span>
				<span>:</span>
				<span>{timeLeft.minutes}</span>
				<span>:</span>
				<span>{timeLeft.seconds}</span>
				</h1>
			) : (
				<h1>It's time.</h1>
			)}
		</div>
    </div>
  );
}

export default App;